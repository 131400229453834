// Initialize
//var bLazy = new Blazy();

// Load Dependencies...
import lozad from 'lozad';
const observer = lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();

var bLazy = new Blazy(
    {
    success: function(ele)
    {
        // Image has loaded
        // Clear Loading Style...
        $('.lazy-bg').css('background-image', 'none')
            .css('width', 'auto')
            .css('height', 'auto');
    }
});

var defaults = {
  backFocus : true,
};
$('[data-fancybox]').fancybox(defaults);
$('[data-toggle="tooltip"]').tooltip();

$('.in-location').on("change", function()
{
   var new_location = $(this).val();

   console.log(new_location);

   $('.location-alternative').val(new_location);
});

// Search Filter Right & Left...
var search_title = $('.search-title-style-1');

if ( search_title.length )
{
    set_search_length();

    function set_search_length()
    {
        var container_width = $('.search-title-style-1 .container').outerWidth();
        var screen_width = $(window).width();

        var filter_width = (screen_width - container_width) / 2 + 24;

        $('.search-filter').width(filter_width);
    }

    $(window).resize(function()
    {
        set_search_length();
    });
}

// Scroll....
$(window).scroll( function()
{
    var scroll = $(window).scrollTop();

    if($(this).scrollTop()>= '10')
    {
        $("header").addClass("scroll");
    }
    else
    {
        $("header").removeClass("scroll");
    }

    if($(this).scrollTop()>= '200')
    {
        set_from_top();
    }
    else
    {
        $('.mobile-cta-links').css('visibility', 'hidden');
    }
});

function set_from_top()
{
    var header_height = $('header').height();
    var cta_height = $('.mobile-cta-links').height();

    if($('.mobile-nav-menu').hasClass('open'))
    {
        $('.mobile-cta-links').attr("style", "display: none !important");
    }

    var window_width = $(window).width();

    if(window_width <= '767')
    {
        $('.mobile-cta-links').css('top', header_height + cta_height -26).css('visibility', 'visible');
    }
    else
    {
        $('.mobile-cta-links').css('top', header_height + cta_height - 10).css('visibility', 'visible');
    }
}

$('.search-toggle-link').on('click', function()
{
    $('.search-form-area').fadeToggle();

    var icon = $(this).find('.as-icon');

    if(icon.hasClass('fa-chevron-down'))
    {
        icon.removeClass('fa-chevron-down');
        icon.addClass('fa-chevron-up');
    }
    else
    {
        icon.removeClass('fa-chevron-up');
        icon.addClass('fa-chevron-down');
    }

    return false;
})

/*-----------------------------------------
* SUBMENU
-----------------------------------------*/
$(".main-menu-container").mouseenter(function(){
    $(this).find('.main-menu').next('.sub-menu').stop().slideDown(120);
    return false;
}).mouseleave(function(){
    $(this).find('.main-menu').next('.sub-menu').stop().slideUp(120);
    return false;
});

$('.search-nav').on('click', function(e)
{
    var page_body = $('body');

    if($(page_body).hasClass('search-page'))
    {
        e.preventDefault();

        var header_height = $('#header').height();

        // $('html, body').animate(
        //     {
        //         scrollTop: $( $.attr(this, 'href') ).offset().top - header_height - 20 // 14 being padding
        //     }, 800);

            $('#top-search').toggleClass('hide-search');

        return false;
    }
    else
    {
        if($(page_body).hasClass('home-page'))
        {
            // Do Nothing - Go To URL...
        }
        else
        {
            // e.preventDefault();

            // var header_height = $('#header').outerHeight();
            // var padding = 14;

            // $('.search-toggle').css('top', header_height - padding);

            // $('.search-toggle').slideToggle();

            // return false;
        }
    }
})

/*-----------------------------------------
* Mobile Navigation
-----------------------------------------*/
var responsiveRight = $(window).width();

mobileNav();
$( window ).resize(function() {
  responsiveRight = $(window).width();
  mobileNav();
});

function mobileNav(){
  if(responsiveRight > "991"){
    responsiveRight = "-400";
  }else{
    responsiveRight = parseInt("-"+responsiveRight);
    $(".mobile-nav-menu .navigation").css({"right":responsiveRight+"px"});
    $(".mobile-nav-menu .background-black").css({"opacity":"0",display:"none"});
  }
}
$(".mobile-nav-menu .burger-icon").click(function()
{
  $('.mobile-nav-menu').addClass('open');

  if($('.mobile-cta-links').is(':visible'))
  {
      $('.mobile-cta-links').attr("style", "display: none !important");
  }

  $( ".mobile-nav-menu .navigation").animate(
      {
        right: "0",
    }, 200, function()
    {
        $(".mobile-nav-menu .background-black").css({display:"block"});
        $(".mobile-nav-menu .background-black").animate({"opacity":"1"},200);
    }
  );
  return false;
});

$(".mobile-nav-menu .mobile-x").click(function()
{
  $(".mobile-nav-menu .background-black").animate({"opacity":"-0"},200, function(){
    $(this).css({display:"none"});
    $('.mobile-nav-menu').removeClass('open');
    $( ".mobile-nav-menu .navigation").animate({right:responsiveRight+"px"},200);
  });
  return false;
});

/*-----------------------------------------
* Scroll Animate
-----------------------------------------*/
$('a.linkSlide').click(function(){
  $('html, body').animate({
    scrollTop: $( $.attr(this, 'href') ).offset().top - 100
  }, 800);
  return false;
});


/*-----------------------------------------
* SELECT2
-----------------------------------------*/
$(".select-pw").select2({
  minimumResultsForSearch: Infinity,
  allowHtml: false,
        closeOnSelect : true,
        allowClear: false,
  tags: true,
  dropdownParent: $('.select-2-results-area__search'),
  dropdownPosition: 'below'
});

$('.select-pw-block').select2(
{
    minimumResultsForSearch: Infinity,
    tags: true,
    dropdownPosition: 'below'
});

/*-----------------------------------------
* HERO SLIDER
-----------------------------------------*/
$('.hero-slider-style-1').slick({
    slidesToShow: 1,
    infinite: true,
    arrows: false,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    accessibility: false
});

/*-----------------------------------------
* GRID PROPERTY SLICK
-----------------------------------------*/
$('.grid-image').slick({
    slidesToShow: 1,
    infinite: true,
    arrows: true,
    dots: false,
    autoplay: false,
    nextSlidesToPreload: 1,
    accessibility: false
});
// $('.grid-image').on('afterChange', function(event, slick, currentSlide, nextSlide){
//   var imgSlider = $('.grid-image .slick-slide.slick-current.slick-active').find('.pw-lazy').data('src');
//   $('.grid-image .slick-slide.slick-current.slick-active').find('.pw-lazy').attr('src',imgSlider);
// });


/*-----------------------------------------
* TESTIMONIALS
-----------------------------------------*/
$('.testimonial-slider').slick({
    slidesToShow: 1,
    infinite: true,
    arrows: true,
    dots: true,
    autoplay: true,
    autoplaySpeed: 6000,
    accessibility: false,
    adaptiveHeight: true,

    appendDots: $(".slide-m-dots"),
    prevArrow: $(".slide-m-prev"),
    nextArrow: $(".slide-m-next")
});

/*-----------------------------------------
* MEMBERS - PROPERTY ALERTS
-----------------------------------------*/
$('.edit-alert').on('click', function()
{
    var id = $(this).data('alert-id');

    $( ".update-alert" ).each(function(  )
    {
        $(this).hide();
    });

    $('.create-alert').hide();
    $('.edit-alert-'+id).show();

    return false;
})

/*---------------------------------------------------
* MEMBER NOTES (DEMO SPECIFIC DUE TO LOOK / FEEL...
----------------------------------------------------*/

$('.modal-toggle').click(function()
{
    var modal_size = $(this).data('modal-size');
    var modal_title = $(this).data('modal-title');
    var modal_type = $(this).data('modal-type');
    var item_id = $(this).data('item-id');
    var modal_message = $(this).data('modal-message');
    var item = $(this).data('item');
    var property_id = $('.property_id').val();

    if (modal_size == 'small')
    {
        $('#global-modal .modal-dialog').addClass('modal-sm');
    }

    $('#global-modal .modal-title').html(modal_title);

    if(modal_type == 'create-note')
    {
        $('#global-modal .modal-footer').hide();
        $('#global-modal .modal-body').html('<div class="popup-note"> <form class="member-note-form" data-toggle="validator">' +
            '<div class="form-group">' +
            '<label class="form__label u-block">Note Content</label>' +
            '<textarea class="form__input u-fullwidth u-p05 note-content u-no-resize -larger" required></textarea>' +
            '<span class="glyphicon form-control-feedback" aria-hidden="true"></span>' +
            '</div>' +
            '<div class="text-center u-mt05 u-mb05"><button class="button -primary f-two text-uppercase f-bold" type="submit">Save Note</button> </div> ' +
            '</form>' +
            '</div></div> ');

        // See if there's any existing notes for this user...
        $.ajax(
            {
                type: 'GET',
                url: '/account/get-user-notes/'+property_id,
                dataType: 'json',
                success: function( data )
                {
                    if(data.url)
                    {
                        // Possibly not needed, but in as a fallback....
                        window.location.replace(data.url);
                    }
                    else
                    {
                        if(data.notes == true)
                        {
                            $('.popup-note').prepend('<p>You have notes saved for this property <a href="/account/notes" class="c-secondary f-bold">View them now</a></p>')
                        }
                    }
                }
            }
        )
    }

    $('.member-note-form').on('submit', function()
    {
        var note_content = $('.note-content').val();

        if(note_content != '')
        {
            $.ajax(
                {
                    headers:
                        {
                            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                        },
                    type: 'POST',
                    url: '/account/notes',
                    data: { note_content: note_content, property_id: property_id  },
                    success: function ( data )
                    {
                        $('.member-notify').fadeIn();
                        $('.member-notify .alert-heading').html(data.message);

                        setTimeout(function()
                        {
                            $('.member-notify').fadeOut();
                        }, 5000) // 5 Seconds...

                        // Close Modal
                        $('#global-modal').modal('hide');
                    }
                }
            )
        }
        else
        {
            alert("Please fill in some data!");
        }

        return false;
    })

})

$('.property-back-link').on('click', function(e){
    e.preventDefault();
    let elem = $(this);
    if(document.referrer.length) {
        if(document.referrer.includes('/property-for-sale') || document.referrer.includes('?page=')) {
            return location.href = document.referrer;
        }
    }
    return location.href = elem.data('href');
});

// Add Opacity To Property Alert & Main Banner
$(window).scroll(function()
{
    $('#header').addClass('-scrolled');
    $('.property-alert-cta .property-alert-cta__inner').addClass('-scrolled');

    clearTimeout($.data(this, 'scrollTimer'));
    $.data(this, 'scrollTimer', setTimeout(function()
    {
        // do something
        $('.property-alert-cta .property-alert-cta__inner').removeClass('-scrolled');
        $('#header').removeClass('-scrolled');
    }, 500));
});
