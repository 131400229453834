jQuery(document).ready(function($) {

// Refresh CSRF token before submitting the search form
    $('.search2and3').on('click', function(event) {
        event.preventDefault(); // Prevent the default form submission

        $.ajax({
            url: '/refresh-csrf-token',
            method: 'GET',
            dataType: 'json'
        })
        .done(function (response) {
            // Update the CSRF token in the form
            $('input[name="_token"]').val(response.csrfToken);
            // Submit the search form
            $('#search-form').submit();
        })
        .fail(function (error) {
            console.log(error);
        });
    });

    $('select[name=sort]').on('change', function(){
        var dest = $(this).val();
        if (dest) { window.location.replace(dest); }
    });

    /*------------------------------------
        DROPDOWN FORMAT
    ------------------------------------*/
    function formatRepo (repo) {

      if (repo.loading) return repo.name;
       var markup = "<div class='select2-result-repository clearfix'>" +
       "<div class='select2-result-repository__meta'>"
       if (repo.name) {
         markup += "<div class='select2-result-repository__description'>" + repo.name+'';
       }
       markup += "</div></div>";
       return markup;
     }

    function formatRepoSelection (repo) {
      if (repo.name === undefined) {
           return repo.text;
       } else {
           return repo.name;
       }
    }

    var language = $('.language').val();
    var url = "/properties/get_locationList";

    if(language !== 'en')
    {
        url = "/"+language+"/properties/get_locationList";
    }

    /*------------------------------------
        SELECT 2 - AJAX - LOCATIONS
    ------------------------------------*/
    $(".select-pw-ajax-locations").each(function(index) {
 
  $(this).on("select2:open", function (e) {
    var $select2Container = $(this).data("select2").$dropdown;
    var uniqueClass = "select2-open-container-" + index; 
    $select2Container.addClass(uniqueClass);
  });
});

    $(".select-pw-ajax-locations").select2(
        {
        multiple: true,
        allowHtml: true,
        closeOnSelect : false,
        allowClear: true,
        minimumInputLength: 0,
        minimumResultsForSearch: Infinity,
        dropdownParent: $('.select-2-results-area__search'),
        dropdownPosition: 'below',
        tags: true,
        placeholder: "All Locations",
        ajax: {
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            url: url,
            dataType: 'json',
            type: 'POST',
            delay: 250,
            data: function (params) {
                var query = {
                  q: params.term,
                  page: params.page || 1,
                  type: 'public'
                }
                return query;
            },
            processResults: function (data, params) {
                //console.log(data.items);
                var query = {
                    results: data.items,
                    pagination: {
                        more: (data.page * 10) < data.total_count
                    }
                }
                return query;
            },
            cache: true,
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        templateResult: formatRepo, // omitted for brevity, see the source of this page
        templateSelection: formatRepoSelection // omitted for brevity, see the source of this page
    });

 setTimeout(function() {
        var hiddenInputValue = $(".location-alternative").val();
        var selectedValues = hiddenInputValue.split(',');

        // Pre-select values from the hidden input
        $(".select-pw-ajax-locations").val(selectedValues).trigger('change');
         $(".location-alternative").val(hiddenInputValue);
    }, 300);


    function search_navigation()
    {
        var target = $("header .search-nav");

        if(target.length)
        {
            target.on('click', function(e){
                e.preventDefault();

                var current_url = window.location.href;
    
                if(current_url.indexOf("property-for-sale") > -1 || current_url.indexOf("property-for-rent") > -1)
                {
                    e.preventDefault();
                    $('#top-search').slideToggle('fast');
                }
            });
        }
    }

    //search_navigation();
   

    // Allow Submit On Search Complete...
    // $(".select-pw-ajax-locations").bind("change keypress", function()
    // {
    //     if (window.event.keyCode === 13)
    //     {
    //         // Allow Submission Of Form...
    //         $('#search-form').submit();
    //     }
    // });
    //
    // $(".select-pw-ajax-locations").on('select2:select', function (e)
    // {
    //     select_change(true);
    // });
    //
    // function select_change(change)
    // {
    //     if(change == true)
    //     {
    //         // Watch For Enter Button Press...
    //         $(document).on('keypress',function(e)
    //         {
    //             if(e.which == 13)
    //             {
    //                 // Submit the Form....
    //                 $('#search-form').submit();
    //             }
    //         });
    //     }
    // }


});
