$(function()
{
    /*------------------------------
    * PROPERTY SLIDER
    *------------------------------*/
    $('.propertySlider-style-1').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        fade: false,
        adaptiveHeight: false,
        asNavFor: '.propertySliderNav-style-1'
    });

    $('.propertySliderNav-style-1').slick(
        {
        slidesToShow: 8,
        //slidesToShow: 10,
        //slidesToShow: 6,
        slidesToScroll: 1,
        asNavFor: '.propertySlider-style-1',
        dots: false,
        centerMode: false,
        arrows: false,
        focusOnSelect: true,
        responsive: [
           {
             breakpoint: 415,
             settings: {
               slidesToShow: 3
             }
           },
             {
               breakpoint: 601,
               settings: {
                 slidesToShow: 4
             }
           }
         ]
    });

    /*------------------------------
    * STIICKY
    *------------------------------*/
    $("#banner-cta-sticky-1").sticky({topSpacing:0});

    /*------------------------------
    * TAB FLEXY - PROPERTY 1
    *------------------------------*/
    $( window ).resize(function() {
        var pwTabWidth = parseInt(($(".pw-tab-width").outerWidth()));
        tabContentHeight(pwTabWidth);
    });

    var pwTabWidth = parseInt(($(".pw-tab-width").outerWidth()));
    tabContentHeight(pwTabWidth);

    function tabContentHeight(pwTabWidth){
        $('.tab-conten-height').css({'min-height':pwTabWidth+'px'});
    }

    var share_dropdown = $(".dropdown-share");

    $('.dropdown-toggler').click(function()
    {
        share_dropdown.toggleClass('open');

        if(share_dropdown.hasClass('open'))
        {
            share_dropdown.slideDown("fast");
        }
        else
        {
            share_dropdown.slideUp("fast");
        }

        return false;
    });


});
